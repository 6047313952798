.ImageListItem {
  .ImageOverlay {
    height: 0px !important;
    overflow: hidden;
    opacity: 0;
    transition: 0.2s ease all;
  }
  &:hover {
    .ImageOverlay {
      height: 100% !important;
      opacity: 1;
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
