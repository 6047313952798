@use "styles/colors" as *;

.ManoPricesHeader {
  button {
    border: 3px solid white !important;
    color: white !important;
    background-color: transparent !important;
    transition: 0.2s ease all;
    &:hover {
      background-color: white !important;
      color: $mano-4 !important;
    }
  }
}
