@use "styles/colors" as *;

.Link {
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 0%;
    background-color: white;
    opacity: 0;
    position: absolute;
    left: 50%;
    bottom: -10%;
    transition: all 0.25s ease;
  }
  &:hover {
    p {
      transition: 0.25s ease;
      margin-right: 10px !important;
    }
    &::after {
      left: 0%;
      width: 100%;
      opacity: 1;
    }
  }
}
