.WelcomeSection {
  transform: translateY(-73px);
  background: black;
  background: url("https://res.cloudinary.com/disyx1lwa/image/upload/v1646421656/egriapartman/1_xlqdd8.jpg")
    rgba(0, 0, 0, 0.725);
  height: calc(60vh);
  min-height: 600px !important;
  padding-top: 60px;
  @media screen and (max-width: 768px) {
    height: calc(40vh);
  }
  @media screen and (max-width: 1024px) {
    transform: translateY(-60px);
  }
}
