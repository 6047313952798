.ApartmentSelector {
  background: black;
  background: url("https://res.cloudinary.com/disyx1lwa/image/upload/v1648029868/egriapartman/egerBackground_jxw3ie.jpg")
    rgba(0, 0, 0, 0.725);
}

.ApartmentSelectionsContainer {
  min-height: calc(100vh - 124px) !important;
}

.ApartmentSelectOption {
  .ImageOverlay {
    height: 0px !important;
    overflow: hidden;
    opacity: 0;
    transition: 0.2s ease all;
  }
  &:hover {
    .ImageOverlay {
      height: 100% !important;
      opacity: 1;
    }
  }
}
