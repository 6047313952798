$darkBrown: #1f231c;

// Mano
$mano-1: #f7f7f7;
$mano-2: #eeeeee;
$mano-3: #929aab;
$mano-4: #212832;
$mano-5: #15191f;
$mano-6: #484b4e;

// Travertin
$travertin-1: #f5f2ed;
$travertin-2: #f5efe5;
$travertin-3: #bfa87f;
$travertin-4: #79582f;
$travertin-5: #202020;
