@use "styles/colors" as *;

.inclined {
  font-family: "Nothing You Could Do", cursive !important;
}

button {
  background: white !important;
  font-size: 14px !important;
  padding: 14px 36px !important;
  border-radius: 0 !important;
}

body {
  padding: 0px !important;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $mano-5;
  }
  &::-webkit-scrollbar-track {
    background: $mano-2 !important;
  }
}
