@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

* {
  font-family: "Ubuntu", sans-serif !important;
}

html,
body {
  min-width: 300px !important;
  overflow-x: hidden;
}

#root {
  white-space: pre-line;
}
