@use "styles/colors" as *;

.WhiteNavbar {
  // color: $mano-4 !important;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.75);
  .BookNowButton {
    background-color: $mano-4 !important;
    color: white !important;
    transition: 0.2s ease all;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      height: 300px;
      width: 60px;
      background: rgba(255, 255, 255, 0.35);
      transform: rotate(35deg) translateY(-100px) translateX(-180px);
      transition: all 0s ease;
    }
    &:hover {
      &::before {
        transform: rotate(35deg) translateY(-100px) translateX(160px);
        opacity: 1;
        transition: all 0.7s ease;
      }
    }
  }
}

.MuiModal-root,
.MuiBackdrop-root {
  margin-top: 60px !important;
}

.MuiPaper-elevation {
  margin-top: 60px !important;
  padding: 12px 12px 12px 12px !important;
}

.MenuIconLine {
  height: 3px;
  width: 30px;
  margin: 6px 0px;
  transition: all 0.4s ease;
}
